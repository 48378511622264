import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';

import { RootState } from 'app/store';
import { User, Token, LoginPayload, AuthResponse } from 'common/ApiTypes';
import { activateUserPremiumCodeActions } from 'features/user/actions';

/**
 * ACTIONS
 */
export const getUserInfoActions = createAsyncAction(
  'authService/GET_USER_INFO_REQUEST',
  'authService/GET_USER_INFO_SUCCESS',
  'authService/GET_USER_INFO_FAILURE'
)<undefined, AuthResponse, undefined>();

export const loginAppleActions = createAsyncAction(
  'authService/LOGIN_APPLE_REQUEST',
  'authService/LOGIN_APPLE_SUCCESS',
  'authService/LOGIN_APPLE_FAILURE'
)<LoginPayload, AuthResponse, undefined>();

export const loginGoogleActions = createAsyncAction(
  'authService/LOGIN_GOOGLE_REQUEST',
  'authService/LOGIN_GOOGLE_SUCCESS',
  'authService/LOGIN_GOOGLE_FAILURE'
)<LoginPayload, AuthResponse, undefined>();

export const loginFacebookActions = createAsyncAction(
  'authService/LOGIN_FACEBOOK_REQUEST',
  'authService/LOGIN_FACEBOOK_SUCCESS',
  'authService/LOGIN_FACEBOOK_FAILURE'
)<LoginPayload, AuthResponse, undefined>();

export const logoutAction = createAction('authService/LOGOUT')();

const actions = {
  logoutAction,
  loginAppleActions,
  loginGoogleActions,
  loginFacebookActions,
  getUserInfoActions,
  activateUserPremiumCodeActions,
};

export type AuthServiceAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
type AuthServiceState = Readonly<{
  user: User | null;
  token: Token | null;
  isAuthenticating: boolean;
}>;

const initialState: AuthServiceState = {
  isAuthenticating: false,
  user: null,
  token: null,
};

const isAuthenticating = createReducer(initialState.isAuthenticating)
  .handleAction(
    [loginAppleActions.request, loginGoogleActions.request, loginGoogleActions.request],
    () => true
  )
  .handleAction(
    [
      loginAppleActions.success,
      loginAppleActions.failure,
      loginGoogleActions.success,
      loginGoogleActions.failure,
      loginFacebookActions.success,
      loginFacebookActions.failure,
    ],
    () => false
  );

const user = createReducer(initialState.user)
  .handleAction(
    [
      loginAppleActions.success,
      loginGoogleActions.success,
      loginFacebookActions.success,
      getUserInfoActions.success,
      activateUserPremiumCodeActions.success,
    ],
    (_, { payload: { data } }) => {
      const user: any = Object.assign({}, data);
      delete (user.auth);

      return user;
    }
  )
  .handleAction(
    [loginAppleActions.failure,
    loginGoogleActions.failure,
    loginFacebookActions.failure,
    getUserInfoActions.failure,
    activateUserPremiumCodeActions.failure,
    ],
    (userState) => userState
  );

const token = createReducer(initialState.token)
  .handleAction(
    [loginAppleActions.success, loginGoogleActions.success, loginFacebookActions.success],
    (_, { payload: { data } }) => data.auth.accessToken
  )
  .handleAction(
    [loginAppleActions.failure, loginGoogleActions.failure, loginFacebookActions.failure],
    () => initialState.token
  );

export const authServiceReducer = combineReducers({
  isAuthenticating,
  user,
  token,
});

/**
 * SELECTORS
 */
export const selectAuthServiceState = (state: RootState) => state.authService;

export const selectIsAuthenticating = (state: RootState) => {
  return selectAuthServiceState(state).isAuthenticating;
};

export const selectUser = (state: RootState): User => selectAuthServiceState(state).user as User;
export const selectToken = (state: RootState): string => selectAuthServiceState(state).token as string;

export const selectIsLoggedIn = createSelector(selectToken, (token) => !!token);
