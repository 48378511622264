import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Button, Modal } from 'antd';
import Meta from 'antd/lib/card/Meta';
import Avatar from 'antd/lib/avatar/avatar';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import { selectUser } from 'common/services/auth';
import { getExpireDate, isPremium } from 'common/functions';

const GetPremiumSale0001 = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)
  const [buyPackageType, setBuyPackageType] = useState('')
  const user = useSelector((state: RootState) => selectUser(state))

  if (!isPremium(user)) {
    return (
      <>
        <div className="site-card-wrapper">
          <h2>{t('getSalePremium')}</h2>
          <Row gutter={16}>
            <Col lg={6} md={12} sm={12} xs={24}>
              <Card
                style={{ marginTop: 10 }}
                cover={
                  <p style={{ textAlign: 'center' }}>
                    <img
                      alt="example"
                      style={{ paddingTop: 30, maxWidth: 128 }}
                      src="/assets/images/packages/1year.png"
                    />
                  </p>
                }
                actions={[
                  <Button onClick={() => { setBuyPackageType('p12month'); setVisible(true) }} title={t('purchase')} >{t('purchase')}</Button>
                ]}
              >
                <Meta
                  title={t('salePackages.p12month.title')}
                  description={[
                    <span key="p12month-1">
                      {t('saleOff')} {t('salePackages.p12month.salePrice')} (<del>{t('salePackages.p12month.price')}</del>)
                    </span>,
                    <p key="p12month-2">{t('salePackages.p12month.description')}</p>
                  ]}
                />
              </Card>
            </Col>
            <Col lg={6} md={12} sm={12} xs={24}>
              <Card
                style={{ marginTop: 10 }}
                cover={
                  <p style={{ textAlign: 'center' }}>
                    <img
                      alt={t('salePackages.p12month.title')}
                      style={{ paddingTop: 30, maxWidth: 128 }}
                      src="/assets/images/packages/lifetime.png"
                    />
                  </p>
                }
                actions={[
                  <Button onClick={() => { setBuyPackageType('lifetime'); setVisible(true) }} title={t('purchase')} >{t('purchase')}</Button>
                ]}
              >
                <Meta
                  title={t('salePackages.lifetime.title')}
                  description={[
                    <span key="lifetime-1">
                      {t('saleOff')} {t('salePackages.lifetime.salePrice')} (<del>{t('salePackages.lifetime.price')}</del>)
                    </span>,
                    <p key="lifetime-2">{t('salePackages.lifetime.description')}</p>
                  ]}
                />
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          title={t('getPremium')}
          visible={visible}
          cancelText={t('close')}
          onOk={() => { setVisible(!visible) }}
          onCancel={() => { setVisible(!visible) }}
        >
          <p>{t('makeYourPaymentDirectlyIntoOurBankAccount')}</p>
          <p>{t('bankAccountNumber')}: 25006492901</p>
          <p>{t('bankName')}</p>
          <p>{t('theAccountHolder')}: {t('companyName')}</p>
          <p></p>
          <p>{t('transferAmount')}: {buyPackageType === 'p12month' ? t('salePackages.p12month.salePriceEnVi') : t('salePackages.lifetime.salePriceEnVi')}</p>
          <p>{t('transferContent')}: <b>{user.email ? user.email : user.shortId} - {user.phone ? user.phone : t('your phone number')}</b></p>
          <p><b>{t('note')}: </b>{t('pleaseSendUsTheTransferInvoice', { content: `${user.email ? user.email : user.shortId} - ${user.phone ? user.phone : t('yourPhoneNumber')}` })}</p>
          <p><i>{t('theActivationCodeWillSentToYouWithin24h')}</i></p>
          <p><a href="tel:(+84) 0889.113.623">{t('support')}: (+84) 0889.113.623</a></p>
        </Modal>
      </>
    );
  }

  return (
    <Row justify="center">
      <Col xs={24} sm={12} md={12} lg={6}>
        <Card
          style={{ marginTop: 10 }}
          cover={
            <p style={{ textAlign: 'center' }}>
              <img
                alt={t('salePackages.p12month.title')}
                style={{ paddingTop: 30, maxWidth: 128 }}
                src={user.isLifetime === 1 ? "/assets/images/packages/lifetime.png" : "/assets/images/packages/1year.png"}
              />
            </p>
          }
        >
          <Meta
            avatar={<Avatar src={user.avatar} />}
            title={user.displayName}
            description={user.isLifetime === 1 ? t('salePackages.lifetime.title') : getExpireDate(user)}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default GetPremiumSale0001;
