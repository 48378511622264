import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'common/components';
import UpgradePremiumContainer from './screens/upgrade-premium';

export const UPGRADE_PREMIUM_ROUTE_PREFIX = '/upgrade-premium';

export const UPGRADE_PREMIUM_ROUTE_PATHS = {
  upgradePremium: UPGRADE_PREMIUM_ROUTE_PREFIX,
}

const UpgradePremiumRoutes = () => (
  <Switch>
    <Route exact path={UPGRADE_PREMIUM_ROUTE_PREFIX} component={UpgradePremiumContainer} />
    <Route component={NotFound} />
  </Switch>
);

export default UpgradePremiumRoutes;
