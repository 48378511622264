import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

import { isDev } from 'config';


// Maps to public/locales/:language_code
export const LANGUAGE_CODES: any = {
  en: 'en',
  vi: 'vi',
};

const defaultLanguage = LANGUAGE_CODES.vi;

export function getUserUiLanguage(): string {
  let language = localStorage.getItem('language');
  try {
    if (language && LANGUAGE_CODES[language]) {
      return language;
    }

    if (navigator.language === 'vi-VN') {
      return LANGUAGE_CODES.vi;
    }

  } catch (error) {
    return defaultLanguage;
  }

  return defaultLanguage;
}

export function setUserUiLanguage(language: string) {
  try {
    if (language && LANGUAGE_CODES[language]) {
      localStorage.setItem('language', language)
    }
  } catch (error) {
    localStorage.removeItem('language')
  }
}

// see: https://www.i18next.com/overview/configuration-options
i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    debug: isDev,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: getUserUiLanguage(),
    fallbackLng: LANGUAGE_CODES.vi,
    supportedLngs: [LANGUAGE_CODES.en, LANGUAGE_CODES.vi],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
  });

export const t = i18next.t.bind(i18next);

export default i18next;
