import { trackProgress } from 'common/services/trackProgress';
import apiClient from 'common/services/apiClient';
import { ActivateUserPremiumCodePayload, AnyResponse } from 'common/ApiTypes';

export const apiCallIds = {
  ACTIVATE_USER_PREMIUM_CODE: 'ACTIVATE_USER_PREMIUM_CODE',
};

const api = {
  activateUserPremiumCode: (data: ActivateUserPremiumCodePayload) => {
    data.code = data.code.toUpperCase()
    data.codeId = data.codeId.toUpperCase()

    return trackProgress(
      apiClient.post<AnyResponse>('web/users/activeUserPremiumCode', data),
      apiCallIds.ACTIVATE_USER_PREMIUM_CODE,
    );
  }
};

export default api;
