import React from 'react';
import { connect } from 'react-redux';

import { loginAppleActions, loginGoogleActions, loginFacebookActions } from 'common/services/auth';
import { useTrackProgress } from 'common/services/trackProgress';

import { apiCallIds } from '../../api';
import Login from './view';

const mapDispatchToProps = {
  loginWithApple: loginAppleActions.request,
  loginWithGoogle: loginGoogleActions.request,
  loginWithFacebook: loginFacebookActions.request,
};

type Props = typeof mapDispatchToProps;

const LoginContainer = ({ loginWithApple, loginWithGoogle, loginWithFacebook }: Props) => {
  const isInProgress = useTrackProgress(apiCallIds.LOGIN_WITH_APPLE);

  return (
    <Login
      isLoading={isInProgress}
      onAppleLogin={loginWithApple}
      onGoogleLogin={loginWithGoogle}
      onFacebookLogin={loginWithFacebook}
    />
  );
};

export default connect(null, mapDispatchToProps)(LoginContainer);
