import React from 'react';
import { useTranslation } from 'react-i18next';
import Sale from 'features/dashboard/components/Sale';
import { useSelector } from 'react-redux';
import { selectUser } from 'common/services/auth';

const Dashboard = () => {
  const { t } = useTranslation();

  const user = useSelector(selectUser)

  return (
    <>
      <div className="site-card-wrapper">
        <h4 style={{ textAlign: 'right' }}>{t('hiHaveANiceDay', { displayName: user ? user.displayName : '...' })}</h4>
      </div>
      <Sale />
    </>
  );
};

export default Dashboard;
