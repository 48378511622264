import React, { ReactNode } from 'react';
import { Layout } from 'antd';

type Props = {
  children: ReactNode;
};

const AuthLayout = ({ children }: Props) => {
  return (
    <Layout className="auth-page-layout">
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default AuthLayout;
