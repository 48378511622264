const RESPONSE_CODES = {
  // User premium code
  USER_PREMIUM_CODE_NOT_FOUND: '10101',
  THIS_PREMIUM_CODE_NOT_FOR_YOU: '10102',
  USER_PREMIUM_ALREADY_USED: '10103',
  USER_PREMIUM_EXPIRE: '10104',
};

export {
  RESPONSE_CODES,
};
