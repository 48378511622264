import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AnyResponse } from 'common/ApiTypes';
import { activateUserPremiumCodeActions } from './actions';
import api from './api';


/**
 * SAGAS
 */
function* activateUserPremiumCode({ payload }: ReturnType<typeof activateUserPremiumCodeActions.request>) {
  try {
    const res: AxiosResponse<AnyResponse> = yield call(api.activateUserPremiumCode, payload);

    yield put(activateUserPremiumCodeActions.success(res.data));
  } catch {
    yield put(activateUserPremiumCodeActions.failure());
  }
}

export function* userSaga() {
  yield takeLatest(activateUserPremiumCodeActions.request, activateUserPremiumCode);
}
