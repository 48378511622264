import React, { Suspense, ReactNode } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ConfigProvider, Spin } from 'antd';
import vi_VN from 'antd/lib/locale/vi_VN';
import en_US from 'antd/lib/locale/en_US';
import { Route, Switch } from 'react-router-dom';
import { rootPath } from 'config';
import { ErrorBoundary, LoadingScreen, NotFound } from 'common/components';
import { useTrackProgress } from 'common/services/trackProgress';
import AuthRoutes, { AUTH_ROUTE_PREFIX } from 'features/auth/routes';
import { store } from './store';
import history from './history';
import MainLayout from './MainLayout';
import { getUserUiLanguage } from 'common/services/i18next';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const Root = () => {
  const language = getUserUiLanguage();

  return (
    <HelmetProvider context={helmetContext}>
      <ErrorBoundary>
        <ConfigProvider locale={language === 'vi' ? vi_VN : en_US}>
          <Suspense fallback={<LoadingScreen />}>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <GlobalSpinner>
                  <Switch>
                    <Route path={AUTH_ROUTE_PREFIX} component={AuthRoutes} />
                    <Route path={rootPath} component={MainLayout} />
                    <Route component={NotFound} />
                  </Switch>
                </GlobalSpinner>
              </ConnectedRouter>
            </Provider>
          </Suspense>
        </ConfigProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

const GlobalSpinner = ({ children }: { children: ReactNode }) => {
  const isInProgress = useTrackProgress();

  return (
    <Spin spinning={isInProgress} size="large">
      {children}
    </Spin>
  );
};

export default Root;
