import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'common/components';
import DownLoadContainer from './screens/download';

export const DOWNLOAD_ROUTE_PREFIX = '/download';

export const DOWNLOAD_ROUTE_PATHS = {
  download: DOWNLOAD_ROUTE_PREFIX,
}

const DownLoadRoutes = () => (
  <Switch>
    <Route exact path={DOWNLOAD_ROUTE_PREFIX} component={DownLoadContainer} />
    <Route component={NotFound} />
  </Switch>
);

export default DownLoadRoutes;
