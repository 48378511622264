import { all, fork } from 'redux-saga/effects';

import { authSaga } from 'features/auth/ducks';
import { userSaga } from 'features/user/ducks';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
  ]);
}
