import { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Rule } from 'antd/lib/form';

const MIN_USER_PREMIUM_CODE_LENGTH = 9;
const MIN_USER_PREMIUM_CODE_ID_LENGTH = 6;

const required = (t: TFunction): Rule => ({
  required: true,
  message: t('validation.required', { defaultValue: 'Field is required' }),
});

const userPremiumCodeCodeLength = (t: TFunction): Rule => ({
  min: MIN_USER_PREMIUM_CODE_LENGTH,
  max: MIN_USER_PREMIUM_CODE_LENGTH,
  message: t('activatePremiumCode.field.code.validation', { length: MIN_USER_PREMIUM_CODE_LENGTH, }),
});

const userPremiumCodeCodeIdLength = (t: TFunction): Rule => ({
  min: MIN_USER_PREMIUM_CODE_ID_LENGTH,
  max: MIN_USER_PREMIUM_CODE_ID_LENGTH,
  message: t('activatePremiumCode.field.codeId.validation', { length: MIN_USER_PREMIUM_CODE_ID_LENGTH, }),
});

const useFormRules = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      required: required(t),
      codeLength: userPremiumCodeCodeLength(t),
      codeIdLength: userPremiumCodeCodeIdLength(t),
    }),
    [t]
  );
};

export default useFormRules;
