import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAUwdxZrS1E_hjAqCyYrD5B2F12lJGCJ1k',
  authDomain: 'nana-lingo-pro.firebaseapp.com',
  databaseURL: 'https://nana-lingo-pro.firebaseio.com',
  projectId: 'nana-lingo-pro',
  storageBucket: 'nana-lingo-pro.appspot.com',
  messagingSenderId: '861092481191',
  appId: '1:861092481191:web:e4cd4d34275aa327ceb382',
  measurementId: 'G-9SLEQ0LJGN',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export { firebaseApp };
