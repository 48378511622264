import { Col, Row } from 'antd';
import { isMobile } from 'common/functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Support = () => {
  const { t, i18n } = useTranslation()

  return (
    <Row justify="center">
      <h2 style={{ textAlign: "center", marginTop: isMobile ? 0 : 40, marginBottom: 30, fontSize: isMobile ? 22 : 28, fontWeight: 'bold' }}>{t('downloadPage.title')}</h2>
      <Col span="24" style={{ textAlign: 'center' }}>
        <p><img alt="" src="/assets/images/app-icon.png" style={{ width: isMobile ? 130 : 180, borderRadius: 16 }} /></p>
        <h2 style={{ fontSize: 24, fontWeight: 'bold', marginTop: 20, marginBottom: 15 }}>{t('app.title')}</h2>
        <h3 style={{ marginBottom: 30 }}>{t('app.slogan')}</h3>
        <p>
          <a href="https://apps.apple.com/app/nana-lingo/id1493885911">
            <img alt="" src={`/assets/images/app_store_badge-${i18n.language}.svg`} style={{ maxWidth: '100%', width: 150 }} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.nanalingo.app">
            <img alt="" src={`/assets/images/google-play-badge-${i18n.language}.png`} style={{ maxWidth: '100%', width: 190 }} />
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default Support;
