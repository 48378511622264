import { GlobalOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import React, { useState } from 'react';
import i18next, { getUserUiLanguage, setUserUiLanguage } from 'common/services/i18next';

interface SelectLangProps {
  className?: string;
}

const SelectLang = () => {
  const [uiLanguage, setUiLanguage] = useState(getUserUiLanguage());


  const changeLang = ({ key }: any): void => {
    setUiLanguage(key);
    setUserUiLanguage(key);
    i18next.changeLanguage(key);
  };

  const locales = ['en', 'vi'];
  const languageLabels: any = {
    'en': 'English',
    'vi': 'Vietnamese',
  };
  const languageIcons: any = {
    'en': '🇺🇸',
    'vi': '🇻🇳',
  };
  const langMenu = (
    <Menu selectedKeys={[uiLanguage]} onClick={changeLang}>
      {locales.map((locale) => (
        <Menu.Item key={locale}>
          <span role="img" aria-label={languageLabels[locale]}>
            {languageIcons[locale]}
          </span>{' '}
          {languageLabels[locale]}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown
      overlay={langMenu}>
      <span className="selectLanguage">
        <GlobalOutlined />
      </span>
    </Dropdown>
  );
};

export default SelectLang;
