import React from 'react';
import UpgradePremium from './view';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const UpgradePremiumContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('upgradePremium.title')}</title>
        <meta name="description" content={t('upgradePremium.desc')} />
      </Helmet>
      <UpgradePremium />
    </>
  );
};

export default UpgradePremiumContainer
