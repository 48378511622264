import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'common/components';
import ActivePremiumCodeContainer from './screens/ActivePremiumCode'

const USER_ROUTE_PREFIX = '/user';

export const USER_ROUTE_PATHS = {
  user: USER_ROUTE_PREFIX,
  activateUserPremiumCode: `${USER_ROUTE_PREFIX}/active-premium-code`
}

const UserRoutes = () => {
  return (
    <Switch>
      {/* <Route path={USER_ROUTE_PATHS.user} component={ActivePremiumCodeContainer} /> */}
      <Route path={USER_ROUTE_PATHS.activateUserPremiumCode} component={ActivePremiumCodeContainer} />
      <Route component={NotFound} />
    </Switch>
  )
};

export default UserRoutes;
