import React from 'react';
import GetPremiumSale0001 from 'features/upgrade-premium/components/GetPremiumSale0001';

const UpgradePremium = () => {

  return (
    <GetPremiumSale0001 />
  );
};

export default UpgradePremium;
