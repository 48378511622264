import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'common/components';
import SupportContainer from './screens/support';

export const SUPPORT_ROUTE_PREFIX = '/support';

export const SUPPORT_ROUTE_PATHS = {
  support: SUPPORT_ROUTE_PREFIX,
}

const SupportRoutes = () => (
  <Switch>
    <Route exact path={SUPPORT_ROUTE_PREFIX} component={SupportContainer} />
    <Route component={NotFound} />
  </Switch>
);

export default SupportRoutes;
