import { Card, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Support = () => {
  const { t } = useTranslation()

  return (
    <Row justify="center">
      <Col xs={24} sm={12} md={12} lg={8}>
        <Card title={t('support')} bordered={false} style={{ width: 400, maxWidth: '100%' }}>
          <p>Email: <a href="mailto:support@nanalingo.com">support@nanalingo.com</a></p>
          <p>{t('phone')}: (+84) 0889.113.623</p>
          <p>{t('supportPage.desc')}</p>
        </Card>
      </Col>
    </Row>
  );
};

export default Support;
