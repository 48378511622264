import React from "react";
import { RootState } from "./store";
import { selectUser } from "common/services/auth";
import { connect } from "react-redux";
import { Dropdown, Menu, Avatar } from "antd";
import { Link } from "react-router-dom";
import { AUTH_ROUTER_PATHS } from "features/auth/routes";
import { useTranslation } from "react-i18next";
import SelectLang from "./SelectLang";

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const AvatarDropdownContainer = ({ user }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="header-menu-right">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <Link to={AUTH_ROUTER_PATHS.logout}>{t('logout')}</Link>
            </Menu.Item>
          </Menu>
        }
      >
        <span className="action account">
          <Avatar size="small" src={user.avatar} className="avatar" alt="avatar" />
          <span className="name">
            Hi, {user.firstName}
          </span>
        </span>
      </Dropdown>
      <SelectLang />
    </div >
  );
}

export default connect(mapStateToProps)(AvatarDropdownContainer);
