import { trackProgress } from 'common/services/trackProgress';
import apiClient from 'common/services/apiClient';
import { LoginPayload, AuthResponse } from 'common/ApiTypes';

export const apiCallIds = {
  GET_USER_INFO: 'GET_USER_INFO',
  LOGIN_WITH_APPLE: 'LOGIN_WITH_APPLE',
  LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE',
  LOGIN_WITH_FACEBOOK: 'LOGIN_WITH_FACEBOOK',
};

export default {
  getUserInfo: () =>
    trackProgress(
      apiClient.get<AuthResponse>('/web/users/info'),
      apiCallIds.GET_USER_INFO
    ),
  loginWithApple: (data: LoginPayload) =>
    trackProgress(
      apiClient.post<AuthResponse>('/web/auth/userApple', data),
      apiCallIds.LOGIN_WITH_APPLE
    ),

  loginWithGoogle: (data: LoginPayload) =>
    trackProgress(
      apiClient.post<AuthResponse>('/web/auth/userGoogle', data),
      apiCallIds.LOGIN_WITH_GOOGLE
    ),

  loginWithFacebook: (data: LoginPayload) =>
    trackProgress(
      apiClient.post<AuthResponse>('/web/auth/userFacebook', data),
      apiCallIds.LOGIN_WITH_FACEBOOK
    ),
};
