import React, { useContext, useCallback } from 'react';
import { Link, useLocation, Switch, Route } from 'react-router-dom';
import { HomeOutlined, BarcodeOutlined, ShoppingOutlined, QuestionCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import IsLoggedIn from 'common/services/auth/guards/IsLoggedIn';
import { NotFound } from 'common/components';
import { rootPath } from 'config';
import { AdminLayout, AdminLayoutContext, SidebarMenu, SidebarState } from 'common/components/AdminLayout';
import DashboardRoutes from 'features/dashboard/routes';
import { useTranslation } from 'react-i18next';
import AvatarDropdown from './AvatarDropdown';
import UserRoutes, { USER_ROUTE_PATHS } from 'features/user/routes';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { selectUser } from 'common/services/auth';
import { isPremium } from 'common/functions';
import UpgradePremiumRoutes, { UPGRADE_PREMIUM_ROUTE_PATHS } from 'features/upgrade-premium/routes';
import SupportRoutes, { SUPPORT_ROUTE_PATHS } from 'features/support/routes';
import DownLoadRoutes, { DOWNLOAD_ROUTE_PATHS } from 'features/download/routes';

const MainLayout = () => {
  return (
    <AdminLayout
      logo={<Logo />}
      headerContent={<AvatarDropdown />}
      sidebarContent={<Sidebar />}
    >
      <Switch>
        <Route exact path={rootPath} component={DashboardRoutes} />
        <Route path={USER_ROUTE_PATHS.user} component={UserRoutes} />
        <Route path={UPGRADE_PREMIUM_ROUTE_PATHS.upgradePremium} component={UpgradePremiumRoutes} />
        <Route path={SUPPORT_ROUTE_PATHS.support} component={SupportRoutes} />
        <Route path={DOWNLOAD_ROUTE_PATHS.download} component={DownLoadRoutes} />
        <Route component={NotFound} />
      </Switch>
    </AdminLayout>
  );
}

export default IsLoggedIn(MainLayout);

const Logo = () => {
  const { sidebarState } = useContext(AdminLayoutContext);

  return (
    <h1
      style={{
        color: sidebarState === SidebarState.CLOSED_DRAWER ? 'black' : 'white',
        margin: 0,
        padding: sidebarState === SidebarState.CLOSED_DRAWER ? '0 20px' : '20px 0',
        textAlign: 'center',
      }}
    >
      {sidebarState === SidebarState.COLLAPSED_SIDEBAR ? 'NaNa' : 'NaNa Lingo'}
    </h1>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useSelector((state: RootState) => selectUser(state));

  const { toggle, sidebarState } = useContext(AdminLayoutContext);
  const onClick = useCallback(
    () => (sidebarState === SidebarState.OPEN_DRAWER ? toggle() : undefined),
    [sidebarState, toggle]
  );

  return (
    <SidebarMenu onClick={onClick} selectedKeys={[pathname]}>
      <SidebarMenu.Item key="/" icon={<HomeOutlined />}>
        <Link to="/">
          {t('dashboard.title')}
        </Link>
      </SidebarMenu.Item>

      {!isPremium(user) && (
        <SidebarMenu.Item key={UPGRADE_PREMIUM_ROUTE_PATHS.upgradePremium} icon={<ShoppingOutlined />}>
          <Link to={UPGRADE_PREMIUM_ROUTE_PATHS.upgradePremium}>
            {t('upgradePremium.title')}
          </Link>
        </SidebarMenu.Item>
      )}

      {!isPremium(user) && (
        <SidebarMenu.Item key={USER_ROUTE_PATHS.activateUserPremiumCode} icon={<BarcodeOutlined />}>
          <Link to={USER_ROUTE_PATHS.activateUserPremiumCode}>
            {t('activatePremiumCode.title')}
          </Link>
        </SidebarMenu.Item>
      )}
      <SidebarMenu.Item key={DOWNLOAD_ROUTE_PATHS.download} icon={<CloudDownloadOutlined />}>
        <Link to={DOWNLOAD_ROUTE_PATHS.download}>
          {t('downloadPage.title')}
        </Link>
      </SidebarMenu.Item>
      <SidebarMenu.Item key={SUPPORT_ROUTE_PATHS.support} icon={<QuestionCircleOutlined />}>
        <Link to={SUPPORT_ROUTE_PATHS.support}>
          {t('supportPage.title')}
        </Link>
      </SidebarMenu.Item>
    </SidebarMenu>
  );
};
