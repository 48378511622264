import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'common/components';
import DashboardContainer from './screens/home'
import { rootPath } from 'config';

export const DASHBOARD_ROUTE_PREFIX = rootPath;

const DashboardRoutes = () => (
  <Switch>
    <Route exact path={DASHBOARD_ROUTE_PREFIX} component={DashboardContainer} />
    <Route component={NotFound} />
  </Switch>
);

export default DashboardRoutes;
