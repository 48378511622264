import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AuthResponse } from 'common/ApiTypes';
import { loginAppleActions, loginGoogleActions, loginFacebookActions, getUserInfoActions } from 'common/services/auth';

import api from './api';

/**
 * SAGAS
 */
function* userInfo() {
  try {
    const res: AxiosResponse<AuthResponse> = yield call(api.getUserInfo);

    yield put(getUserInfoActions.success(res.data));
  } catch {
    yield put(getUserInfoActions.failure());
  }
}

function* appleLogin({ payload }: ReturnType<typeof loginAppleActions.request>) {
  try {
    const res: AxiosResponse<AuthResponse> = yield call(api.loginWithApple, payload);

    yield put(loginAppleActions.success(res.data));
  } catch {
    yield put(loginAppleActions.failure());
  }
}

function* googleLogin({ payload }: ReturnType<typeof loginGoogleActions.request>) {
  try {
    const res: AxiosResponse<AuthResponse> = yield call(api.loginWithGoogle, payload);

    yield put(loginGoogleActions.success(res.data));
  } catch {
    yield put(loginGoogleActions.failure());
  }
}

function* facebookLogin({ payload }: ReturnType<typeof loginFacebookActions.request>) {
  try {
    const res: AxiosResponse<AuthResponse> = yield call(api.loginWithFacebook, payload);

    yield put(loginFacebookActions.success(res.data));
  } catch {
    yield put(loginFacebookActions.failure());
  }
}

export function* authSaga() {
  yield takeLatest(getUserInfoActions.request, userInfo);
  yield takeLatest(loginAppleActions.request, appleLogin);
  yield takeLatest(loginGoogleActions.request, googleLogin);
  yield takeLatest(loginFacebookActions.request, facebookLogin);
}
