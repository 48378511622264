import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

import { rootPath } from 'config';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Result
      icon={<img src="https://image.freepik.com/free-vector/error-404-page-file-found-concept-vector-illustration_173706-60.jpg" alt="" />}
      title={t('notFound.title')}
      subTitle={t('notFound.subtitle')}
      extra={
        <Link to={rootPath} replace>
          <Button type="primary">{t('goHome')}</Button>
        </Link>
      }
    />
  );
};

export default NotFound;
