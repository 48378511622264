import React from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UPGRADE_PREMIUM_ROUTE_PATHS } from 'features/upgrade-premium/routes';

const Sale = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <br />
      <h2 style={{ textAlign: "center" }}>{t('getSalePremium')}</h2>
      <br />
      <Row justify="center">
        <p>
          <Link to={UPGRADE_PREMIUM_ROUTE_PATHS.upgradePremium}>
            {i18n.language === 'en' && (
              <img style={styles.images} alt="Merry Christmas Sale" src="/assets/sales/00001/sale-00001-en-f-merry-christmas-2020.png"></img>
            )}
            {i18n.language === 'vi' && (
              <img style={styles.images} alt="Merry Christmas Sale" src="/assets/sales/00001/sale-00001-vi-f-merry-christmas-2020.png"></img>
            )}
          </Link>
        </p>
      </Row>
    </>
  );
};

const styles = {
  images: {
    borderRadius: 8,
    width: 800,
    maxWidth: '100%',
  }
}

export default Sale;
