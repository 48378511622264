import { ActivateUserPremiumCodePayload, AnyResponse } from "common/ApiTypes";
import { ActionType, createAsyncAction } from "typesafe-actions";

export const activateUserPremiumCodeActions = createAsyncAction(
  'userService/ACTIVATE_USER_PREMIUM_CODE_REQUEST',
  'authService/ACTIVATE_USER_PREMIUM_CODE_SUCCESS',
  'authService/ACTIVATE_USER_PREMIUM_CODE_FAILURE'
)<ActivateUserPremiumCodePayload, AnyResponse, undefined>();

const actions = { activateUserPremiumCodeActions };

export type UserServiceAction = ActionType<typeof actions>;
