import axios, { AxiosError } from 'axios';
import { notification } from 'antd';
import { store } from 'app/store';
import { t } from 'common/services/i18next';
import { selectToken, logoutAction } from './auth';
import { get } from 'lodash'
import { RESPONSE_CODE_VALUES } from './responseCodeValues';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use(async (config) => {
  const token = selectToken(store.getState());

  if (token) {
    config.headers.common = config.headers.common ?? {};
    config.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (!axios.isCancel(error)) {
      if (error.response?.status === 401) {
        store.dispatch(logoutAction());
      }

      showErrorMessage(error);

      throw error;
    }
  }
);

function showErrorMessage(error: AxiosError) {
  const errorMsg = extractErrorMsg(error);
  if (Array.isArray(errorMsg)) {
    errorMsg.forEach((err) => {
      notification.open({
        message: t('notice'),
        description: `${err}`,
      });
    });
  } else {
    notification.open({
      message: t('notice'),
      description: `${errorMsg}`,
    });
  }
}

function extractErrorMsg(error: AxiosError): string | string[] {
  const { response, message } = error;
  const request: XMLHttpRequest | undefined = error.request;

  // Server responded with a status code that falls out of the range of 2xx
  if (response) {
    if (response.data?.message) {
      return response.data.message;
    } else if (response.data?.error?.message) {
      return response.data.error.message;
    } else if (response.data?.error?.inner) {
      return response.data.error.inner;
    }

    const error = get(response.data, 'error', [])
    if (Array.isArray(error)) {
      // TODO: Refactor later
      if (error.length === 1 && error[0]['code'] && RESPONSE_CODE_VALUES.includes(error[0]['code'])) {
        return t(`errors.${error[0]['code']}`);
      }
    }

    return response.statusText;
  }
  // The request was made but no response was received
  else if (request) {
    return t('api.unexpectedError', { defaultValue: 'Unexpected error occurred' });
  }

  // Something happened in setting up the request that triggered an Error
  return message;
}

export default apiClient;
