import React from 'react';
import Support from './view';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const DownLoadContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('downloadPage.title')}</title>
        <meta name="description" content={t('downloadPage.desc')} />
      </Helmet>
      <Support />
    </>
  );
};

export default DownLoadContainer
