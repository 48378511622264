import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { NotFound } from 'common/components';
import LoginGuard from 'common/services/auth/guards/LoginGuard';
import LogoutGuard from 'common/services/auth/guards/LogoutGuard';

import Login from './screens/Login';
import Logout from './screens/Logout';

export const AUTH_ROUTE_PREFIX = '/auth';

export const AUTH_ROUTER_PATHS = {
  login: `${AUTH_ROUTE_PREFIX}/login`,
  logout: `${AUTH_ROUTE_PREFIX}/logout`,
};

const LoginPage = LoginGuard(Login);
const LogoutPage = LogoutGuard(Logout);

const AuthRoutes = () => (
  <Switch>
    <Route
      exact
      path={AUTH_ROUTE_PREFIX}
      render={() => <Redirect to={AUTH_ROUTER_PATHS.login} />}
    />
    <Route exact path={AUTH_ROUTER_PATHS.login} component={LoginPage} />
    <Route exact path={AUTH_ROUTER_PATHS.logout} component={LogoutPage} />
    <Route component={NotFound} />
  </Switch>
);

export default AuthRoutes;
