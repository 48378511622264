import React from 'react';
import { connect } from 'react-redux';
import ActivePremiumCode from './view';
import { apiCallIds } from 'features/user/api';
import { useTrackProgress } from 'common/services/trackProgress';
import { activateUserPremiumCodeActions } from 'features/user/actions';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const mapDispatchToProps = {
  activateUserPremiumCode: activateUserPremiumCodeActions.request,
};

type Props = typeof mapDispatchToProps;

const ActivePremiumCodeContainer = ({ activateUserPremiumCode }: Props) => {
  const isInProgress = useTrackProgress(apiCallIds.ACTIVATE_USER_PREMIUM_CODE);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('activatePremiumCode.title')}</title>
        <meta name="description" content={t('activatePremiumCode.desc')} />
      </Helmet>

      <ActivePremiumCode
        isLoading={isInProgress}
        onSubmit={activateUserPremiumCode}
      />
    </>
  );
};

export default connect(null, mapDispatchToProps)(ActivePremiumCodeContainer);
