import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Switch } from 'antd';
import { Helmet } from 'react-helmet-async';
import AuthLayout from '../../components/AuthLayout';
import { firebaseApp } from '../../../../common/base';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { LoginPayload } from 'common/ApiTypes';
import { FacebookFilled, AppleFilled, GoogleOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { setUserUiLanguage, getUserUiLanguage } from 'common/services/i18next';

type Props = {
  isLoading: boolean;
  onAppleLogin: (values: LoginPayload) => void;
  onGoogleLogin: (values: LoginPayload) => void;
  onFacebookLogin: (values: LoginPayload) => void;
};

const LoginForm = ({ isLoading, onAppleLogin, onGoogleLogin, onFacebookLogin }: Props) => {
  const [uiLanguage, setUiLanguage] = useState(getUserUiLanguage());
  const { t } = useTranslation();

  const appleAuthenticate = () => {
    const authProvider = new firebase.auth.OAuthProvider('apple.com');

    authProvider.addScope('email');
    authProvider.addScope('name');

    firebaseApp.auth().signInWithPopup(authProvider).then(appleAuthHandler);
  };

  const appleAuthHandler = async (authData: firebase.auth.UserCredential) => {
    const firebaseIdToken = (await authData.user?.getIdToken()) || '';

    onAppleLogin({
      firebaseIdToken,
      deviceType: 'WEB',
      uiLanguage: uiLanguage,
    });
  };

  const googleAuthenticate = () => {
    const authProvider = new firebase.auth.GoogleAuthProvider();

    firebaseApp.auth().signInWithPopup(authProvider).then(googleAuthHandler);
  };

  const googleAuthHandler = async (authData: firebase.auth.UserCredential) => {
    const firebaseIdToken = (await authData.user?.getIdToken()) || '';

    onGoogleLogin({
      firebaseIdToken,
      deviceType: 'WEB',
      uiLanguage: uiLanguage,
    });
  };

  const fbAuthenticate = () => {
    const authProvider = new firebase.auth.FacebookAuthProvider();

    firebaseApp.auth().signInWithPopup(authProvider).then(fbAuthHandler);
  };

  const fbAuthHandler = async (authData: firebase.auth.UserCredential) => {
    const firebaseIdToken = (await authData.user?.getIdToken()) || '';

    onFacebookLogin({
      firebaseIdToken,
      deviceType: 'WEB',
      uiLanguage: uiLanguage,
    });
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>{t('login.title')}</title>
        <meta name="description" content={t('login.description')} />
      </Helmet>

      <div className="login-form">
        <div className="logo">
          <img alt="logo" className="logo" src="https://nanalingo.com/img/logo.png" />
        </div>
        <div className="desc">{t('app.slogan')}</div>
        <h3 className="login-title">{t('login.title')}</h3>
        <div>
          <Button
            block
            icon={<AppleFilled />}
            type="default"
            htmlType="button"
            onClick={appleAuthenticate}
            loading={isLoading}
            className="login-button"
          >
            {t('login.loginWithApple')}
          </Button>
        </div>
        <div>
          <Button
            block
            icon={<GoogleOutlined />}
            type="primary"
            danger
            htmlType="button"
            onClick={googleAuthenticate}
            loading={isLoading}
            className="login-button"
          >
            {t('login.loginWithGoogle')}
          </Button>
        </div>
        <div>
          <Button
            block
            icon={<FacebookFilled />}
            type="primary"
            htmlType="button"
            onClick={fbAuthenticate}
            loading={isLoading}
            className="login-button"
          >
            {t('login.loginWithFacebook')}
          </Button>
        </div>
        <div className="switch-language">
          {t('language')} <Switch
            checkedChildren={t('lang.vi')}
            unCheckedChildren={t('lang.en')}
            defaultChecked={uiLanguage === 'en'}
            onChange={(checked) => {
              const language = checked ? 'en' : 'vi';
              setUiLanguage(language);
              setUserUiLanguage(language);
              i18next.changeLanguage(language);
            }}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginForm;
