import React from 'react';
import Dashboard from './view';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const DashboardContainer = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('app.title')}</title>
        <meta name="description" content={t('app.slogan')} />
      </Helmet>

      <Dashboard />
    </>
  );
};

export default DashboardContainer
