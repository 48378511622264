import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivateUserPremiumCodePayload, User } from 'common/ApiTypes';
import { useSelector } from 'react-redux';
import { selectUser } from 'common/services/auth';
import { Avatar, Button, Card, Col, Form, Input, Row } from 'antd';
import useFormRules from 'features/user/useFormRules';
import Meta from 'antd/lib/card/Meta';
import { getExpireDate, isPremium } from 'common/functions';

type Props = {
  isLoading: boolean;
  onSubmit: (values: ActivateUserPremiumCodePayload) => void;
};

const ActivePremiumCode = ({ isLoading, onSubmit }: Props) => {
  const { t } = useTranslation();
  const user: User = useSelector(selectUser);
  const { required, codeLength, codeIdLength } = useFormRules();


  const isPremiumUser = isPremium(user);

  return (
    <>

      {isPremiumUser && (
        <Row justify="center">
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card
              style={{ marginTop: 10 }}
              cover={
                <p style={{ textAlign: 'center' }}>
                  <img
                    alt={t('packages.p12month.title')}
                    style={{ paddingTop: 30, maxWidth: 128 }}
                    src={user.isLifetime === 1 ? "/assets/images/packages/lifetime.png" : "/assets/images/packages/1year.png"}
                  />
                </p>
              }
            >
              <Meta
                avatar={<Avatar src={user.avatar} />}
                title={user.displayName}
                description={user.isLifetime === 1 ? t('packages.lifetime.title') : getExpireDate(user)}
              />
            </Card>
          </Col>
        </Row>
      )}

      {!isPremiumUser && (
        <Row justify="center">
          <Col xs={24} sm={12} md={12} lg={8}>
            <h2>{t('activatePremiumCode.title')}</h2>
            <Form onFinish={onSubmit} layout="vertical">
              <Form.Item
                label={t('activatePremiumCode.field.codeId.label')}
                name="codeId"
                rules={[required, codeIdLength]}
                normalize={value => (value || '').toUpperCase()}
              >
                <Input autoFocus placeholder={t('activatePremiumCode.field.codeId.placeholder', { length: 6 })} />
              </Form.Item>
              <Form.Item
                label={t('activatePremiumCode.field.code.label')}
                name="code"
                rules={[required, codeLength]}
                normalize={value => (value || '').toUpperCase()}
              >
                <Input placeholder={t('activatePremiumCode.field.code.placeholder', { length: 9 })} />
              </Form.Item>
              <Form.Item
                hidden
                name="activeToUserId"
                initialValue={user._id}
              >
                <Input type="hidden" />
              </Form.Item>
              <Button block type="primary" htmlType="submit" loading={isLoading}>
                {t('activatePremiumCode.button.activate')}
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ActivePremiumCode;
